import React, { Component, Fragment } from "react";
import Toggle from "react-toggle";
import { Row, Col } from "react-bootstrap";
export default class RoleForm extends Component {
  state = {
    import: false,
    content: false,
    anon: false,
    export_local: false,
    export_extern: false,
    query: false,
    auto_query: false,
    delete: false,
    admin: false,
    modify: false,
    cd_burner: false,
    create_report: false,
    sharing: false,
    card_sharing: false,
    autorouting: false,
    premium: false,
    create_patient_report: false,
    edit_patient_report: false,
    view_patient_report: false,
    request_patient_report: false,
    delete_report: false,
    addendun: false,
    delete_req_report: false,
    can_change_report_status: false,
    report_with_pdf: false,
    view_imagin: false,
    delete_imagin: false,
    can_req_imaging: false,
    can_add_radiologist_email: false,
    can_view_assign_caselist: false,
    can_view_admin_caselist: false,
    can_assign_doctors: false,
    can_add_table: false,
    can_download_zip: false,
    share_card_download: false,
    meddream: false,
    view_and_download_light: false,
    view_monitoring: false,
    view_system_monitoring: false,
    view_user_activity: false,
    view_my_dashboard: false,
    signature_compulsory: false,
    moderator: false,
    doctor_description_required: false,
  };

  componentDidMount = () => {
    if (this.props.data != null) {
      this.setState({
        import: this.props.data.import,
        content: this.props.data.content,
        anon: this.props.data.anon,
        exportLocal: this.props.data.export_local,
        exportExtern: this.props.data.export_extern,
        query: this.props.data.query,
        autoQuery: this.props.data.auto_query,
        delete: this.props.data.delete,
        admin: this.props.data.admin,
        modify: this.props.data.modify,
        cd_burner: this.props.data.cd_burner,
        create_report: this.props.data.create_report,
        sharing: this.props.data.sharing,
        card_sharing: this.props.data.card_sharing,
        autorouting: this.props.data.autorouting,
        premium: this.props.data.premium,
        create_patient_report: this.props.data.create_patient_report,
        edit_patient_report: this.props.data.edit_patient_report,
        view_patient_report: this.props.data.view_patient_report,
        request_patient_report: this.props.data.request_patient_report,
        delete_report: this.props.data.delete_report,
        addendun: this.props.data.addendun,
        can_finalize_report: this.props.data.can_finalize_report,
        view_request_report: this.props.data.view_request_report,
        delete_req_report: this.props.data.delete_req_report,
        can_change_report_status: this.props.data.can_change_report_status,
        report_with_pdf: this.props.data.report_with_pdf,
        view_imagin: this.props.data.view_imagin,
        delete_imagin: this.props.data.delete_imagin,
        can_req_imaging: this.props.data.can_req_imaging,
        can_add_radiologist_email: this.props.data.can_add_radiologist_email,
        can_view_assign_caselist: this.props.data.can_view_assign_caselist,
        can_view_admin_caselist: this.props.data.can_view_admin_caselist,
        can_assign_doctors: this.props.data.can_assign_doctors,
        can_add_table: this.props.data.can_add_table,
        can_download_zip: this.props.data.can_download_zip,
        share_card_download: this.props.data.share_card_download,
        meddream: this.props.data.meddream,
        view_and_download_light: this.props.data.view_and_download_light,
        view_monitoring: this.props.data.view_monitoring,
        view_system_monitoring: this.props.data.view_system_monitoring,
        view_user_activity: this.props.data.view_user_activity,
        view_my_dashboard: this.props.data.view_my_dashboard,
        signature_compulsory: this.props.data.signature_compulsory,
        moderator: this.props.data.moderator,
        doctor_description_required:
          this.props.data.doctor_description_required,
      });
    }
  };

  render = () => {
    return (
      <Fragment>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Administration</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.admin}
              onChange={() =>
                this.setState((prevState) => ({ admin: !prevState.admin }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Anonymisation</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.anon}
              onChange={() =>
                this.setState((prevState) => ({ anon: !prevState.anon }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Auto-Query</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.autoQuery}
              onChange={() =>
                this.setState((prevState) => ({
                  autoQuery: !prevState.autoQuery,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Content</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.content}
              onChange={() =>
                this.setState((prevState) => ({ content: !prevState.content }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Delete</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.delete}
              onChange={() =>
                this.setState((prevState) => ({ delete: !prevState.delete }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Local Export</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.exportLocal}
              onChange={() =>
                this.setState((prevState) => ({
                  exportLocal: !prevState.exportLocal,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Remote Export</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.exportExtern}
              onChange={() =>
                this.setState((prevState) => ({
                  exportExtern: !prevState.exportExtern,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Query</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.query}
              onChange={() =>
                this.setState((prevState) => ({ query: !prevState.query }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Import</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.import}
              onChange={() =>
                this.setState((prevState) => ({ import: !prevState.import }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Modify</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.modify}
              onChange={() =>
                this.setState((prevState) => ({ modify: !prevState.modify }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>CD Burner</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.cd_burner}
              onChange={() =>
                this.setState((prevState) => ({
                  cd_burner: !prevState.cd_burner,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Create report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.create_report}
              onChange={() =>
                this.setState((prevState) => ({
                  create_report: !prevState.create_report,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Sharing</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.sharing}
              onChange={() =>
                this.setState((prevState) => ({ sharing: !prevState.sharing }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Card sharing</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.card_sharing}
              onChange={() =>
                this.setState((prevState) => ({
                  card_sharing: !prevState.card_sharing,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Premiun Card Sharing</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.premium}
              onChange={() =>
                this.setState((prevState) => ({ premium: !prevState.premium }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Share Card Download</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.share_card_download}
              onChange={() =>
                this.setState((prevState) => ({
                  share_card_download: !prevState.share_card_download,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Create Patient Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.create_patient_report}
              onChange={() =>
                this.setState((prevState) => ({
                  create_patient_report: !prevState.create_patient_report,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Edit Patient Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.edit_patient_report}
              onChange={() =>
                this.setState((prevState) => ({
                  edit_patient_report: !prevState.edit_patient_report,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>View Patient Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.view_patient_report}
              onChange={() =>
                this.setState((prevState) => ({
                  view_patient_report: !prevState.view_patient_report,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Request Patient Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.request_patient_report}
              onChange={() =>
                this.setState((prevState) => ({
                  request_patient_report: !prevState.request_patient_report,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Add Radiologist Email (R.R.)</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_add_radiologist_email}
              onChange={() =>
                this.setState((prevState) => ({
                  can_add_radiologist_email:
                    !prevState.can_add_radiologist_email,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Report Delete</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.delete_report}
              onChange={() =>
                this.setState((prevState) => ({
                  delete_report: !prevState.delete_report,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>Addendum</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.addendun}
              onChange={() =>
                this.setState((prevState) => ({
                  addendun: !prevState.addendun,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Finalize Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_finalize_report}
              onChange={() =>
                this.setState((prevState) => ({
                  can_finalize_report: !prevState.can_finalize_report,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>View Req. Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.view_request_report}
              onChange={() =>
                this.setState((prevState) => ({
                  view_request_report: !prevState.view_request_report,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Delete Req. Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.delete_req_report}
              onChange={() =>
                this.setState((prevState) => ({
                  delete_req_report: !prevState.delete_req_report,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Change Status of Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_change_report_status}
              onChange={() =>
                this.setState((prevState) => ({
                  can_change_report_status: !prevState.can_change_report_status,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Report With Image</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.report_with_pdf}
              onChange={() =>
                this.setState((prevState) => ({
                  report_with_pdf: !prevState.report_with_pdf,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Can Request Imaging</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_req_imaging}
              onChange={() =>
                this.setState((prevState) => ({
                  can_req_imaging: !prevState.can_req_imaging,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>View Advance Imaging</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.view_imagin}
              onChange={() =>
                this.setState((prevState) => ({
                  view_imagin: !prevState.view_imagin,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Delete Advance Imaging </h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.delete_imagin}
              onChange={() =>
                this.setState((prevState) => ({
                  delete_imagin: !prevState.delete_imagin,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Can View Assign Caselist</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_view_assign_caselist}
              onChange={() =>
                this.setState((prevState) => ({
                  can_view_assign_caselist: !prevState.can_view_assign_caselist,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Can View Admin Caselist</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_view_admin_caselist}
              onChange={() =>
                this.setState((prevState) => ({
                  can_view_admin_caselist: !prevState.can_view_admin_caselist,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Can Assign Report</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_assign_doctors}
              onChange={() =>
                this.setState((prevState) => ({
                  can_assign_doctors: !prevState.can_assign_doctors,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Can Download Zip</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_download_zip}
              onChange={() =>
                this.setState((prevState) => ({
                  can_download_zip: !prevState.can_download_zip,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Can Add Table</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.can_add_table}
              onChange={() =>
                this.setState((prevState) => ({
                  can_add_table: !prevState.can_add_table,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>View Meddream</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.meddream}
              onChange={() =>
                this.setState((prevState) => ({
                  meddream: !prevState.meddream,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>View and Download Light</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.view_and_download_light}
              onChange={() =>
                this.setState((prevState) => ({
                  view_and_download_light: !prevState.view_and_download_light,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>View Monitoring</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.view_monitoring}
              onChange={() =>
                this.setState((prevState) => ({
                  view_monitoring: !prevState.view_monitoring,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>View System Monitoring</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.view_system_monitoring}
              onChange={() =>
                this.setState((prevState) => ({
                  view_system_monitoring: !prevState.view_system_monitoring,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>View User Activity</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.view_user_activity}
              onChange={() =>
                this.setState((prevState) => ({
                  view_user_activity: !prevState.view_user_activity,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>View My Dashboard</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.view_my_dashboard}
              onChange={() =>
                this.setState((prevState) => ({
                  view_my_dashboard: !prevState.view_my_dashboard,
                }))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={5}>
            <h5>signature compulsory</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.signature_compulsory}
              onChange={() =>
                this.setState((prevState) => ({
                  signature_compulsory: !prevState.signature_compulsory,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5> moderator</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.moderator}
              onChange={() =>
                this.setState((prevState) => ({
                  moderator: !prevState.moderator,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5> doctor_description_required</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.doctor_description_required}
              onChange={() =>
                this.setState((prevState) => ({
                  doctor_description_required:
                    !prevState.doctor_description_required,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={5}>
            <h5>Dicom Router</h5>
          </Col>
          <Col sm={7}>
            <Toggle
              checked={this.state.autorouting}
              onChange={() =>
                this.setState((prevState) => ({
                  autorouting: !prevState.autorouting,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mt-3 text-center">
          <Col>
            <button
              type="button"
              name="create"
              className="otjs-button otjs-button-blue"
              onClick={() => {
                this.props.onSubmitRole(this.state);
              }}
            >
              {" "}
              Validate{" "}
            </button>
          </Col>
        </Row>
      </Fragment>
    );
  };
}
